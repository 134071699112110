import { useState, useRef, useEffect } from "react";
import "./App.css";

function App() {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");

  const PDF_FILE_URL = "https://toebanacoffee.com/Toebana_Coffee_Company_Profile.pdf"

  let interval = useRef();

  const downloadFileAtURL = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Toebana_Coffee_Company_Profile.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const startTimer = () => {
    const countdownDate = new Date("December 1, 2024 00:00:00").getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        // stop our Cimer
        clearInterval(interval.current);
      } else {
        // update timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <section className="timer-container">
        <div className="bg-white w-full h-[75px] flex justify-center items-center">
            <img src="https://res.cloudinary.com/dm8bu2mdo/image/upload/v1729495344/Toebana/Toebana_Horizontal_Logo_xqoesm.png" alt="logo" className="h-[60px] object-contain bg-white"/>
          </div>
      <section className="timer flex flex-col">
        <div>
          <span className="mdi mdi-calendar-clock timer-icon"></span>
          <h2 className="righteous-regular text-4xl uppercase mt-24">Coming Soon</h2>
          <p className=" text-center">
            We are Launching our New Website. <br/> 
            We're working hard to give you the best experience!
          </p>
        </div>
        <div>
          <section>
            <p>{timerDays}</p>
            <p>
              <small>Days</small>
            </p>
          </section>
          <span>:</span>
          <section>
            <p>{timerHours}</p>
            <p>
              <small>Hours</small>
            </p>
          </section>
          <span>:</span>
          <section>
            <p>{timerMinutes}</p>
            <p>
              <small>Minutes</small>
            </p>
          </section>
          <span>:</span>
          <section>
            <p>{timerSeconds}</p>
            <p>
              <small>Seconds</small>
            </p>
          </section>
        </div>
      </section>
      <div className="flex relative z-20 justify-center -mt-28">
        <button 
          onClick={() => downloadFileAtURL(PDF_FILE_URL)}
         className="px-6 py-3 bg-primary text-white rounded-full transition duration-300 ease-in-out transform hover:scale-105 hover:bg-[#0F916F] focus:outline-none focus:ring-2 focus:ring-[#8B4513] focus:ring-opacity-50"
        >
          Download Company Profile
        </button>
      </div>
      <div className="fixed bottom-0 left-0 right-0 w-full overflow-hidden">
        <img src="https://res.cloudinary.com/dm8bu2mdo/image/upload/v1729520874/Toebana/coffee_beans_ntg2qt.png" alt="coffee beans" className="w-full object-cover h-auto max-h-[30vh]"/>
      </div>
    </section>
  );
}

export default App;
